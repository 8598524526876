@import "../variables.scss";





.servicePill {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: $grey800;
  border: 3px solid $grey800;
  outline: none!important;
  border-radius: 1.5rem;
  margin-right: .7rem;
  margin-bottom: 1rem;
  transition: $t1;
  cursor: none;
  max-width: 100%;
  overflow: hidden;

  > input {
    display: none;
  }

  > img {
    border-radius: 100%;
    height: clamp(1.5rem, 5.5vh, 3rem);
    width: clamp(1.5rem, 5.5vh, 3rem);
    margin-right: 1.3rem;
    border: 1px solid $white;
  }

  > span {
    text-transform: none;
    font-size: 1.3rem;
    font-weight: 500;
    font-style: italic;
    max-width: 100%;
  }

  &.checked {
    border: none;
    border: 3px solid $grey400;
  }
}

.pill {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 1.5rem;
  margin-right: .7rem;
  margin-bottom: 1rem;
  border: 1px solid $grey400;
  transition: $t1;
  cursor: none;
  max-width: 100%;
  overflow: hidden;

  > input {
    display: none;
  }

  > span {
    text-transform: none;
    font-size: 1rem;
    font-weight: 400;
    font-style: italic;
    max-width: 100%;
    overflow: hidden;
    word-break: break-all;
    height: 1rem;
  }

  &.checked {
    background-color: $white;
    color: $black;
  }

}



.contact {
  height: calc(100vh - 5rem);
  color: $white;
  padding: 2rem 0;
  text-align: left;
  margin-bottom: 4rem;

  .contactFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    //min-height: calc(100vh - 5rem);
  }

  .cardsStack {
    position: relative;
    width: 80vh;
    max-width: 55rem;

    > .card:nth-child(2) .forScrollOverflow {
      overflow-y: auto;
      min-height: 5rem;
      max-height: 40rem;
      margin-bottom: 2rem;
      padding-right: 1rem;

      &::-webkit-scrollbar {
        display: block;
        width: 5px;
      }
      &::-webkit-scrollbar-track {
          background: $grey800;
          border-radius: 5px;
          margin-bottom: 18rem;
      }
      &::-webkit-scrollbar-thumb {
          background-color: $grey500;
          border-radius: 5px;
          border-right: none;
          border-left: none;
      }
    }
  }

  .card {
    background-color: $grey1000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4rem 3rem 4rem 4rem;
    border-radius: 1.5rem;
    height: 80vh;
    width: 80vh;
    max-width: 55rem;
    max-height: 55rem;
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 0 0 3rem rgba($black, .2);
    overflow: hidden;

    /*&:first-of-type {
      padding: 4rem 3rem 4rem 4rem;
    }*/

    &.forSize {
      opacity: 0;
      position: static;
    }

    .error {
      margin-bottom: 1rem;
      color: red; 
      font-size: .9rem;
    }

    .back {
      background-color: $grey800;
      height: 2.2rem;
      width: 2.2rem;
      min-height: 2.2rem;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 1.7rem;
      top: 1.5rem;

      > svg {
        height: 1.1rem;
        width: 1.1rem;
        margin-left: .3rem;
        path { fill: $grey500; }
      }
    }

    .skip {
      position: absolute;
      right: 3rem;
      top: 2.5rem;
      color: $grey500;
      font-weight: 400;
      font-size: .8rem;
    }

    h1 {  
      font-size: clamp(2rem, 7vh, 4rem);
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 3vh;
    }

    .line {
      margin: 1rem 0 2vh;
      width: 100%;
      display: flex;

      > span {
        font-size: 1.1rem;
        color: $grey400;
        font-weight: 500;
        flex: 2;
      }

      > small {
        font-size: 1.1rem;
        color: $grey800;
        transition: $t1;
        &:hover { color: $grey500 }
      }
    }

    .addServices {
      width: 100%;
      margin-bottom: 2vh;
    }

    .ctFrame {
      display: flex;
      position: relative;
      width: 100%;

      > input {
        flex: 2;
        border: 2px solid $grey800;
        background-color: $grey900;
        padding: 1rem 2rem;
        border-radius: 3rem;
        color: $grey400;
        font-weight: 600;
        font-size: 1.05rem;

        &:active {
          outline: none;
          border-color: $grey400;
        }

        &::placeholder {
          font-weight: 600;
          color: $grey800;
        }
      }

      > button {
        position: absolute;
        top: .25rem;
        right: .25rem;
        background-color: $grey800;
        height: calc(100% - .5rem);
        width: unset;
        padding: 0 1.3rem;
        border-top-right-radius: 3rem;
        border-bottom-right-radius: 3rem;
        transition: $t1;

        svg {
          height: 1.3rem;
          transition: $t1;

          line { transition: $t1; stroke: $grey500; }
        }

        &:hover {
          svg line { stroke: $white; }
        }
      }
    }


    .options {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 2rem;
    }

    button {
      width: 100%;
      padding: 1.3rem 8rem;
      font-size: 1.1rem;
      border-radius: 1rem;
      border: 1px solid $grey800;
      text-transform: none;
      font-weight: 600;
      background: none;
      margin-top: auto;
      color: $white;
      transition: $t1;

      &.active {
        background-color: $white;
        color: $grey800;

        &:hover {
          background-color: $white;
          color: $grey800;
        }  
      }
    }
  }


  .after {
    position: relative;
    width: 100%;
    padding: 1rem;

    &::after {
      height: 3rem;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      background: linear-gradient(0deg, rgba($grey1000, 100) 0%, rgba($grey1000, 0) 100%);
    }
  }

  .inputs {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    flex: 2;
    position: relative;
    padding-right: 1rem;

    &::-webkit-scrollbar {
      display: block;
      width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: $grey800;
        border-radius: 5px;
        margin-bottom: 23rem;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $grey500;
        border-radius: 5px;
        border-right: none;
        border-left: none;
    }

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    label {
      font-size: 1rem;
      margin-bottom: .5rem;
      margin-left: .5rem;
      color: $grey400;
    }

    input, textarea {
      //width: 60vw;
      width: 100%;
      margin-bottom: 2rem;
      background-color: $grey900;
      border-radius: 2rem;
      border: 1px solid $grey800;
      padding: .7em 1rem;
      color: $white;
      font-size: 1rem;
      font-weight: 500;

      &::placeholder {
        font-size: 1rem;
        color: $grey800;
      }
    }

    textarea {
      height: 10rem;
      padding: 1rem 1.5rem;
      resize: none;
    }
  }

  .emojis {
    flex: 2;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8rem;
    width: 100%;

    > div {
      position: absolute;
      background-color: $grey800;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > div:nth-child(1) {
      left: calc(50% - 2.5rem);
      padding: 1rem;
      > img {
        height: 3rem;
        width: 3rem;
      }
    }

    > div:nth-child(2) {
      top: calc(50% + 6.5rem);
      right: calc(50% + 7.5rem);
      padding: .3rem;
      > img {
        height: 1rem;
        width: 1rem;
      }
    }

    > div:nth-child(3) {
      bottom: calc(50% + 5rem);
      left: calc(50% + 8.5rem);
      padding: .3rem;
      > img {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  .setUp {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem 0 1rem;

    h1 {
      margin-bottom: 1.5rem;
    }

    span {
      font-size: 1.4rem;
      color: $grey500;
      max-width: 90%;
      line-height: 130%;
      margin-bottom: 4rem;
    }

    button {
      padding: .7rem 2rem;
      width: unset;
      border-radius: 3rem;
      background-color: $grey800;
      border: none;
      font-weight: 500;
      font-size: 1.1rem;
    }
  }
}






@media (max-width: 1000px) {
  .contact {
    padding-top: 2rem;
    min-height: 100vh;
    height: auto;

      
    .cardsStack {
      margin-top: 3rem;
      width: calc(100vw - 2rem);
      max-width: calc(100vw - 2rem);
      height: calc(100vh - 5rem);
      min-height: 55rem;
      overflow: hidden;
    }
  
    .card {
      padding: 4rem;
      width: calc(100% - 3rem);
      height: 70vh;
      min-height: 50rem;
      left: 1rem;
    }
  }
}


@media (max-width: 678px) {
  .servicePill {
    padding: .7rem 1.5rem;
    border-radius: 1rem;
    margin-right: 0;
    margin-bottom: .8rem;
    width: 100%;
  
    > img {
      height: 2.5rem;
      width: 2.5rem;
      margin-right: 3vw;
    }
  
    > span {
      font-size: 1.5rem;
    }
  }
  
  .pill {
    margin-right: .5rem;
    margin-bottom: .6rem;
  
    > span {
      font-size: 1rem;
    }
  }
  
  
  .contact {
    padding-top: 3rem;
    height: calc(100vh - 3rem);
    min-height: 45rem;
  
    .cardsStack {
      width: calc(100vw - 1rem);
      max-width: calc(100vw - 1rem);
      height: calc(100vh - 3rem);
      min-height: 48rem;

      > .card:nth-child(2) .forScrollOverflow {
        overflow-y: auto;
        max-height: 25rem;
      }
    }
  
    .card {
      padding: 3.5rem 2rem 2.5rem;
      width: calc(100% - 1rem);
      height: 100%;
      min-height: 45rem;
      left: .5rem;

      &:nth-child(2), &:nth-child(3) {
        padding-top: 4.5rem;
      }
  
      .back {
        left: 1.5rem;
        top: 1.2rem;
      }
  
      .skip {
        right: 1.7rem;
        top: 2rem;
        font-size: .9rem;
      }
  
      h1 {  
        //font-size: 2.5rem;
        font-size: clamp(1rem, 6vh, 3rem);
        margin-bottom: 2rem;
      }
  
      .line {
        margin: .5rem 0 1.4rem;
      }
  
      .addServices {
        width: 100%;
      }
  
      .ctFrame {
        display: flex;
        position: relative;
        width: 100%;
  
        > input {
          flex: 2;
          border: 2px solid $grey800;
          background-color: $grey900;
          padding: 1rem 2rem;
          border-radius: 3rem;
          color: $grey400;
          font-weight: 600;
  
          &:active {
            outline: none;
            border-color: $grey400;
          }
  
          &::placeholder {
            font-weight: 600;
            color: $grey800;
          }
        }
  
        > button {
          position: absolute;
          top: .25rem;
          right: .25rem;
          background-color: $grey800;
          height: calc(100% - .5rem);
          width: unset;
          padding: 0 1.3rem;
          border-top-right-radius: 3rem;
          border-bottom-right-radius: 3rem;
          transition: $t1;
  
          svg {
            height: 1.3rem;
            transition: $t1;
  
            line { transition: $t1; stroke: $grey500; }
          }
  
          &:hover {
            svg line { stroke: $white; }
          }
        }
      }
  
      .options {
        margin-bottom: 1.5rem;
      }
  
      button {
        padding: 1.3rem 3rem;
        font-size: 1.1rem;
        border-radius: 1.5rem;
        margin-top: auto;
      }
    }
  
  
    .after {
      padding: 1rem;
  
      &::after {
        height: 4rem;
      }
    }
  
    .inputs {
      input, textarea {
        margin-bottom: 1.5rem;
      }
    }
  
    .setUp {
      padding: 2rem 0 3rem;
  
      h1 {
        margin-bottom: 1rem;
      }
  
      span {
        font-size: 1.1rem;
        max-width: 60%;
        margin-bottom: 2rem;
      }
  
      button {
        padding: .7rem 2rem;
        font-size: 1.1rem;
      }
    }
  }

}


@media (max-width: 444px) {
  .servicePill {
    padding: .7rem 1.3rem;
    max-width: 100%;
  
    > img {
      height: 2rem;
      width: 2rem;
    }
  
  }

  .contact {
  
    .card {
      padding: 3.5rem 1.5rem 2rem;
      width: calc(100% - 1rem);
      height: 100%;
      min-height: 45rem;

      .options {
        max-width: 100%;
      }

      .emojis {
        > div:nth-child(1) {
          left: calc(50% - 2.8rem);
          padding: .7rem;
          > img {
            height: 2.5rem;
            width: 2.5rem;
          }
        }
    
        > div:nth-child(2) {
          top: calc(50% + 6.5rem);
          right: calc(50% + 5rem);
          padding: .25rem;
          > img {
            height: .8rem;
            width: .8rem;
          }
        }
    
        > div:nth-child(3) {
          bottom: calc(50% + 5rem);
          left: calc(50% + 4.5rem);
          padding: .3rem;
          > img {
            height: .9rem;
            width: .9rem;
          }
        }
      }

      .setUp {
    
        span {
          max-width: 80%;
        }
      }
    }
  }
}
